import React from 'react';
import wafer from "./Projects/wafer/wafer_project.jpg";

const Wafer = () => {
  return (
    <div>
      <h1>My Wafer Project</h1>
    </div>

    
  );
};

export default Wafer;