import React from 'react';

const Contact = () => {
  return (
    <div>
      <h2>Reach me at:</h2>
    </div>

    
  );
};

export default Contact;