import React from 'react';
import { BrowserRouter as Router, Route, Routes, Link , useLocation} from 'react-router-dom';
import Projects from './Projects'; // Your new page component
import Contact from './Contact'
import Layout from './Layout';
import Home from './Home';
import Wafer from './Wafer';


const App = () => {
  return (
    <Router>
      <div> 

      
      <Routes>
        <Route path="/" element={<Layout />}>
            <Route index element={<Home />} /> {/* Default Home route */}
            <Route path="/projects" element={<Projects />} />
            <Route path="/contact" element={<Contact />} />
            <Route path="/projects/Wafer" element={<Wafer />} />
        </Route>
      </Routes>
      

    
    </div>
    </Router>
    
  );
}

export default App;
