import React from 'react';
import { BrowserRouter as Router, Route, Routes, Link } from 'react-router-dom';
import './Projects.css'; 
import wafer from "./Projects/wafer/wafer_project.jpg";
import claw from "./Projects/claw/claw_project.jpg";
import chair from "./Projects/chair/chair_project.jpg";
import car from "./Projects/car/car_project.jpg";

const Projects = () => {
  return (
    <div>
      <div className='Intro'>
        <h2 className='Title'> Learn about my Projects!</h2>
      </div>
      <div className='Project-Collection'>
        <div className='Project'>
          
          <Link to="/projects" className='Projects-links'>
            <p> Enhancing Silicon Wafer Vacuum Chuck Optical Detection </p>
          </Link>
          
          <Link to="/projects">
            <img src={wafer} className='Project-image'/>
          </Link>
        </div>

        <div className='Project'>
          

          <Link to="/projects" className='Projects-links'>
            <p> Arduino Claw Challenge </p>
          </Link>

          <Link to="/projects" className='Projects-links'>
            <img src={claw} className='Project-image'/>
          </Link>

          
        </div>

        <div className='Project'>
          <Link to="/projects" className='Projects-links'>
            <p> Cardboard Chair Challenge </p>
          </Link>
          
          <Link to="/projects" className='Projects-links'>
            <img src={chair} className='Chair-image'/>
          </Link>
          
        </div>

        <div className='Project'>
          <Link to="/projects" className='Projects-links'>
            <p> RC Car Project (in progress) </p>
          </Link>
          
          <Link to="/projects" className='Projects-links'>
            <img src={car} className='Chair-image'/>
          </Link>
          
        </div>
      </div>
    </div>

    
  );
};

export default Projects;
