import React from 'react';
import { BrowserRouter as Router, Route, Routes, Link , useLocation} from 'react-router-dom';
import Projects from './Projects'; // Your new page component

import Layout from './Layout';
import './Home.css'; // Assuming you have a CSS file for styling
import logo from "./Home/gz-full_logo.png";
import profilePic from "./Home/angela_bday.jpg";



const App = () => {
    
    return (

<div className="main">
      <div className='profilePic'>
        <img src={profilePic} alt="Profile" className="circular-image"/>
      </div>
      <div className="image-container">
          		<img src={logo} alt="Profile" className="logo"/>
      </div>
      <h1 className='name' >Gordon Zhao</h1>
      <div className="about"> 
        <p>Driven and detail-oriented UBC Engineering Physics student with a strong foundation in applied physics, electronics, 
          and problem-solving. </p>
      </div>
      <div className='links'>
        <a href="https://github.com/gzhao08"> 
          <button className='github-button'></button>
        </a>

        <a href="https://www.linkedin.com/in/gzhao08/"> 
          <button className='linkedin-button'></button>
        </a>

        <a href="mailto:gzhao08@student.ubc.ca"> 
          <button className='mail-button'></button>
        </a>

      </div>

      
      </div>
);
}

export default App;