import React from 'react';
import { Outlet, Link } from 'react-router-dom';
import './Layout.css'; 

const Layout = () => {
  return (
    <div>
      {/* Common navigation that appears on every page */}
      

      {/* This is where page-specific content will be rendered */}
      <Outlet />

      {/* Common footer that appears on every page */}
      
      <div className="footer">
        <nav>
        <ul>
            <li><Link to="/">Home</Link></li>
            <li><Link to="/projects">Projects</Link></li>
        </ul>
        </nav>
        
      </div>
    </div>
  );
};

export default Layout;
